// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-business-consent-processing-pd-js": () => import("./../../src/pages/business/consent-processing-pd.js" /* webpackChunkName: "component---src-pages-business-consent-processing-pd-js" */),
  "component---src-pages-careers-js": () => import("./../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-consent-processing-pd-js": () => import("./../../src/pages/consent-processing-pd.js" /* webpackChunkName: "component---src-pages-consent-processing-pd-js" */),
  "component---src-pages-contacts-js": () => import("./../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-dengiru-privacy-policy-js": () => import("./../../src/pages/dengiru/privacy-policy.js" /* webpackChunkName: "component---src-pages-dengiru-privacy-policy-js" */),
  "component---src-pages-faq-js": () => import("./../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-2019-js": () => import("./../../src/pages/legal/2019.js" /* webpackChunkName: "component---src-pages-legal-2019-js" */),
  "component---src-pages-legal-2020-js": () => import("./../../src/pages/legal/2020.js" /* webpackChunkName: "component---src-pages-legal-2020-js" */),
  "component---src-pages-legal-2021-js": () => import("./../../src/pages/legal/2021.js" /* webpackChunkName: "component---src-pages-legal-2021-js" */),
  "component---src-pages-legal-2022-js": () => import("./../../src/pages/legal/2022.js" /* webpackChunkName: "component---src-pages-legal-2022-js" */),
  "component---src-pages-legal-2023-js": () => import("./../../src/pages/legal/2023.js" /* webpackChunkName: "component---src-pages-legal-2023-js" */),
  "component---src-pages-legal-2024-js": () => import("./../../src/pages/legal/2024.js" /* webpackChunkName: "component---src-pages-legal-2024-js" */),
  "component---src-pages-legal-bpa-js": () => import("./../../src/pages/legal/bpa.js" /* webpackChunkName: "component---src-pages-legal-bpa-js" */),
  "component---src-pages-legal-central-bank-license-js": () => import("./../../src/pages/legal/centralBankLicense.js" /* webpackChunkName: "component---src-pages-legal-central-bank-license-js" */),
  "component---src-pages-legal-crs-compliance-js": () => import("./../../src/pages/legal/crsCompliance.js" /* webpackChunkName: "component---src-pages-legal-crs-compliance-js" */),
  "component---src-pages-legal-deposit-insurance-js": () => import("./../../src/pages/legal/depositInsurance.js" /* webpackChunkName: "component---src-pages-legal-deposit-insurance-js" */),
  "component---src-pages-legal-fatca-compliance-js": () => import("./../../src/pages/legal/fatcaCompliance.js" /* webpackChunkName: "component---src-pages-legal-fatca-compliance-js" */),
  "component---src-pages-legal-important-updates-js": () => import("./../../src/pages/legal/importantUpdates.js" /* webpackChunkName: "component---src-pages-legal-important-updates-js" */),
  "component---src-pages-legal-index-js": () => import("./../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-legal-info-rates-js": () => import("./../../src/pages/legal/infoRates.js" /* webpackChunkName: "component---src-pages-legal-info-rates-js" */),
  "component---src-pages-legal-list-insider-info-js": () => import("./../../src/pages/legal/listInsiderInfo.js" /* webpackChunkName: "component---src-pages-legal-list-insider-info-js" */),
  "component---src-pages-legal-ombudsman-js": () => import("./../../src/pages/legal/ombudsman.js" /* webpackChunkName: "component---src-pages-legal-ombudsman-js" */),
  "component---src-pages-legal-osms-2020-report-js": () => import("./../../src/pages/legal/osms2020Report.js" /* webpackChunkName: "component---src-pages-legal-osms-2020-report-js" */),
  "component---src-pages-legal-pci-dss-certified-js": () => import("./../../src/pages/legal/pciDSSCertified.js" /* webpackChunkName: "component---src-pages-legal-pci-dss-certified-js" */),
  "component---src-pages-legal-pd-protection-js": () => import("./../../src/pages/legal/pdProtection.js" /* webpackChunkName: "component---src-pages-legal-pd-protection-js" */),
  "component---src-pages-legal-requisite-js": () => import("./../../src/pages/legal/requisite.js" /* webpackChunkName: "component---src-pages-legal-requisite-js" */),
  "component---src-pages-lp-card-acquiring-js": () => import("./../../src/pages/lp/card-acquiring.js" /* webpackChunkName: "component---src-pages-lp-card-acquiring-js" */),
  "component---src-pages-money-transfer-js": () => import("./../../src/pages/money-transfer.js" /* webpackChunkName: "component---src-pages-money-transfer-js" */),
  "component---src-pages-pricing-js": () => import("./../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-products-account-js": () => import("./../../src/pages/products/account.js" /* webpackChunkName: "component---src-pages-products-account-js" */),
  "component---src-pages-products-escrow-account-js": () => import("./../../src/pages/products/escrow-account.js" /* webpackChunkName: "component---src-pages-products-escrow-account-js" */),
  "component---src-pages-products-marketplaces-js": () => import("./../../src/pages/products/marketplaces.js" /* webpackChunkName: "component---src-pages-products-marketplaces-js" */),
  "component---src-pages-products-online-banking-js": () => import("./../../src/pages/products/online-banking.js" /* webpackChunkName: "component---src-pages-products-online-banking-js" */),
  "component---src-pages-products-payment-bot-js": () => import("./../../src/pages/products/payment-bot.js" /* webpackChunkName: "component---src-pages-products-payment-bot-js" */),
  "component---src-pages-products-payments-js": () => import("./../../src/pages/products/payments.js" /* webpackChunkName: "component---src-pages-products-payments-js" */),
  "component---src-pages-products-payouts-js": () => import("./../../src/pages/products/payouts.js" /* webpackChunkName: "component---src-pages-products-payouts-js" */),
  "component---src-pages-products-sbp-js": () => import("./../../src/pages/products/sbp.js" /* webpackChunkName: "component---src-pages-products-sbp-js" */),
  "component---src-pages-rules-documents-dengi-ru-credit-orgs-js": () => import("./../../src/pages/rules_documents_dengi_ru/credit-orgs.js" /* webpackChunkName: "component---src-pages-rules-documents-dengi-ru-credit-orgs-js" */),
  "component---src-pages-rules-documents-dengi-ru-index-js": () => import("./../../src/pages/rules_documents_dengi_ru/index.js" /* webpackChunkName: "component---src-pages-rules-documents-dengi-ru-index-js" */),
  "component---src-pages-rules-documents-dengi-ru-registration-js": () => import("./../../src/pages/rules_documents_dengi_ru/registration.js" /* webpackChunkName: "component---src-pages-rules-documents-dengi-ru-registration-js" */),
  "component---src-pages-rules-documents-dengi-ru-rules-js": () => import("./../../src/pages/rules_documents_dengi_ru/rules.js" /* webpackChunkName: "component---src-pages-rules-documents-dengi-ru-rules-js" */),
  "component---src-pages-self-employed-js": () => import("./../../src/pages/self-employed.js" /* webpackChunkName: "component---src-pages-self-employed-js" */),
  "component---src-templates-blog-js": () => import("./../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-post-js": () => import("./../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

